import { H, Section } from "@jfrk/react-heading-levels";
import { withComponentDefaults } from "@whitespace/components";
import {
  SearchForm,
  SearchResults,
  URLSearchParamsProvider,
  SearchPagination,
} from "@whitespace/gatsby-plugin-search";
import {
  HTML,
  Image,
} from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import DefaultSearchBackendProvider from "@whitespace/gatsby-theme-wordpress-basic/src/components/DefaultSearchBackendProvider";
import {
  getMainArchivePageTitleFromPageContext,
  getArchiveURLPatternFromPageContext,
} from "@whitespace/gatsby-theme-wordpress-basic/src/contentType";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import {
  useArchiveParamTypes,
  usePageContext,
} from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { getOptionsFromTaxonomy } from "@whitespace/gatsby-theme-wordpress-basic/src/utils";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./Archive.module.css";

Archive.propTypes = {
  className: PropTypes.string,
  components: PropTypes.objectOf(PropTypes.elementType),
  styles: PropTypes.objectOf(PropTypes.string),
  transformParams: PropTypes.func,
};

export default withComponentDefaults(Archive, "archive");

function Archive({
  className,
  styles = defaultStyles,
  transformParams = (params) => params,
  components: { SearchBackendProvider = DefaultSearchBackendProvider } = {
    SearchBackendProvider: DefaultSearchBackendProvider,
  },
  ...restProps
}) {
  const { t, i18n } = useTranslation();
  const paramTypes = useArchiveParamTypes();
  const pageContext = usePageContext();
  const eventsArchive = pageContext?.contentType?.name === "event";
  const [gridView, setGridView] = useState(true);

  const data = useStaticQuery(graphql`
    fragment WP_Evenemangskalender on WP_Evenemangskalender {
      eventsArchiveCustomFields {
        description
        title
        image {
          ...WP_ImageLarge
        }
      }
    }
    query EventsArchiveCustomFields {
      wp {
        sv: evenemangskalender(language: SV) {
          ...WP_Evenemangskalender
        }
        en: evenemangskalender(language: EN) {
          ...WP_Evenemangskalender
        }
        de: evenemangskalender(language: DE) {
          ...WP_Evenemangskalender
        }
      }
    }
  `);

  const { description, title, image } =
    data.wp?.[i18n.language]?.eventsArchiveCustomFields || {};

  const forcedParams = {
    contentType: pageContext.contentType.name,
    sort:
      pageContext.contentType.name === "event"
        ? "sortDate:asc"
        : "sortDate:desc",
  };

  if (eventsArchive) {
    paramTypes.tags = {
      type: "string",
      multi: true,
      control: "select",
      options: getOptionsFromTaxonomy("eventCategory"),
      conditions: { contentType: (value) => value === "event" },
    };
    paramTypes.sort.options = () => ({
      "": t("relevance"),
      "sortDate:asc": t(`date`),
    });
    paramTypes.filter = {
      type: "string",
      control: "checkbox",
      multi: true,
      options: [
        {
          value: "free",
          label: t("onlyFree"),
        },
        {
          value: "bookable",
          label: t("onlyBookable"),
        },
      ],
    };
  }

  return (
    <div
      className={clsx(
        styles.component,
        layout.component,
        layout.componentWidthFull,
        eventsArchive && styles.eventsArchive,
        className,
      )}
      {...restProps}
    >
      {eventsArchive && (
        <Image
          className={styles.image}
          {...image}
          caption={null}
          credit={null}
        />
      )}
      <H className={clsx(styles.title)}>
        {eventsArchive
          ? title
          : getMainArchivePageTitleFromPageContext(pageContext)}
      </H>
      {eventsArchive && (
        <>
          <div className={styles.text}>
            <HTML>{description}</HTML>
          </div>
          <div className={styles.line} />
        </>
      )}
      <Section>
        <URLSearchParamsProvider
          urlPattern={getArchiveURLPatternFromPageContext(pageContext)}
          forcedParams={forcedParams}
          paramTypes={paramTypes}
          decodeParams={({ filter, ...params }) => ({
            ...params,
            filter: filter ? filter.split(",") : [],
          })}
          encodeParams={({ filter, ...params }) => ({
            ...params,
            filter: Array.isArray(filter) ? filter.join(",") : filter,
          })}
        >
          <SearchBackendProvider transformParams={transformParams}>
            <SearchForm
              showHitsTotal={false}
              setGridView={setGridView}
              gridView={gridView}
            />
            <SearchResults gridView={gridView} />
            <SearchPagination />
          </SearchBackendProvider>
        </URLSearchParamsProvider>
      </Section>
    </div>
  );
}
